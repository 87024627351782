<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="1200px"
            :center="true"
            top="50px"
            :close-on-click-modal="false"
            :before-close="() => closeCardRoleDialog('CardRoleEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="CardRole"
                    ref="CardRoleEditForm"
                    label-width="100px"
                    size="min"
                >
                    <div v-if="show_type">
                        <el-descriptions
                            class="margin-top"
                            :column="2"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <div v-if="CardRole.gender === 1"><img :src="$store.state.baseURL+'icon/ziliaonan.png'" height="80px"></div>
                                    <div v-else><img :src="$store.state.baseURL+'icon/ziliaonv.png'" height="80px"></div>
                                    <div>角色图片</div>
                                </template>
                                <common-upload-component
                                    ref="upload"
                                    :ffileList="CardRole.picture"
                                ></common-upload-component>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <div v-if="CardRole.gender === 1"><img :src="$store.state.baseURL+'icon/dangannan.png'" height="80px"></div>
                                    <div v-else><img :src="$store.state.baseURL+'icon/dangannv.png'" height="80px"></div>
                                    <div>角色档案</div>
                                </template>
                                <el-descriptions
                                    class="margin-top"
                                    :column="2"
                                    :labelStyle="{'width':'90px','text-align':'center'}"
                                    :contentStyle="{'text-align': 'center'}"
                                    border
                                    size="samll"
                                >
                                    <el-descriptions-item :span="1">
                                        <template slot="label">
                                            <span>编号</span>
                                        </template>
                                        <el-input v-model="CardRole.no" placeholder="请填写角色编号" clearable class="input_no" size="samll"></el-input>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>名称</span>
                                        </template>
                                        <el-input v-model="CardRole.name" placeholder="请填写角色名称" clearable></el-input>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>性别</span>
                                        </template>
                                        <el-select
                                            v-model="CardRole.gender"
                                            filterable
                                            clearable
                                            placeholder="选择性别"
                                            class="gender_c"
                                        >
                                            <el-option
                                                v-for="item in AllGender"
                                                :key="item.name"
                                                :label="item.name"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>系列</span>
                                        </template>
                                        <el-select
                                            v-model="CardRole.group"
                                            filterable
                                            clearable
                                            placeholder="选择系列"
                                            class="group_c"
                                        >
                                            <el-option
                                                v-for="item in AllGroup"
                                                :key="item.name"
                                                :label="item.name"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>属性</span>
                                        </template>
                                        <el-select
                                            v-model="CardRole.hide"
                                            filterable
                                            clearable
                                            placeholder="选择属性"
                                            class="hide_c"
                                        >
                                            <el-option
                                                v-for="item in AllHide"
                                                :key="item.name"
                                                :label="item.name"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>机密类型</span>
                                        </template>
                                        <el-select
                                            v-model="CardRole.secret_type"
                                            filterable
                                            clearable
                                            multiple
                                            :multiple-limit="3"
                                            placeholder="机密任务类型"
                                            class="secret_type_c"
                                        >
                                            <el-option
                                                v-for="item in AllSecretType"
                                                :key="item.name"
                                                :label="item.name"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-descriptions-item>
                                    <el-descriptions-item :span=2>
                                        <template slot="label">
                                            <span>机密任务</span>
                                        </template>
                                        <el-input v-model="CardRole.secret" clearable></el-input>
                                    </el-descriptions-item>
                                    <el-descriptions-item :span=2>
                                        <template slot="label">
                                            <span>角色密语</span>
                                        </template>
                                        <el-input v-model="CardRole.slogan" clearable></el-input>
                                    </el-descriptions-item>
                                    <el-descriptions-item :span=2>
                                        <template slot="label">
                                            <span>出场语音</span>
                                        </template>
                                        <common-mp3-component
                                            ref="show_upload"
                                            :limit=1
                                            :ffileList="CardRole.show_speech"
                                            note='请以语音内容为文件名，可以使用中文字符，不要使用英文字符'
                                        ></common-mp3-component>
                                    </el-descriptions-item>
                                    <el-descriptions-item :span=2>
                                        <template slot="label">
                                            <span>胜利语音</span>
                                        </template>
                                        <common-mp3-component
                                            ref="win_upload"
                                            :limit=1
                                            :ffileList="CardRole.win_speech"
                                            note='请以语音内容为文件名，可以使用中文字符，不要使用英文字符'
                                        ></common-mp3-component>
                                    </el-descriptions-item>
                                    <el-descriptions-item :span=2>
                                        <template slot="label">
                                            <span>失败语音</span>
                                        </template>
                                        <common-mp3-component
                                            ref="lost_upload"
                                            :limit=1
                                            :ffileList="CardRole.lost_speech"
                                            note='请以语音内容为文件名，可以使用中文字符，不要使用英文字符'
                                        ></common-mp3-component>
                                    </el-descriptions-item>
                                </el-descriptions>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                    <div v-else>
                        <el-descriptions
                            class="margin-top"
                            :column="2"
                            :labelStyle="{'width':'120px','text-align':'center','font-weight':'bold'}"
                            :contentStyle="{'text-align': 'center','min-width':'260px'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <div v-if="CardRole.gender === 1"><img :src="$store.state.baseURL+'icon/ziliaonan.png'" height="80px"></div>
                                    <div v-else><img :src="$store.state.baseURL+'icon/ziliaonv.png'" height="80px"></div>
                                    <div>角色图片</div>
                                </template>
                                <div>
                                    <common-carousel-component
                                        :data="CardRole.picture"
                                    ></common-carousel-component>
                                    <div style="text-align: center;">
                                        <el-tag  effect="plain" size="small" style="cursor: pointer;transition: 0.5s;margin:10px 20px 0 0" @click="$store.commit('edit_speech_url', CardRole.show_speech)">开场告白</el-tag>
                                        <el-tag type="danger" effect="plain" size="small" style="cursor: pointer;transition: 0.5s;margin:10px 20px 0 0" @click="$store.commit('edit_speech_url', CardRole.win_speech)">胜利宣言</el-tag>
                                        <el-tag type="info" effect="plain" size="small" style="cursor: pointer;transition: 0.5s;" @click="$store.commit('edit_speech_url', CardRole.lost_speech)">死亡遗言</el-tag>
                                    </div>
                                </div>
                                <common-upload-component
                                    ref="upload"
                                    v-show="false"
                                    :disabled="true"
                                    :ffileList="CardRole.picture"
                                ></common-upload-component>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <div v-if="CardRole.gender === 1"><img :src="$store.state.baseURL+'icon/dangannan.png'" height="80px"></div>
                                    <div v-else><img :src="$store.state.baseURL+'icon/dangannv.png'" height="80px"></div>
                                    <div>角色档案</div>
                                </template>
                                <el-descriptions
                                    class="margin-top"
                                    :column="2"
                                    :labelStyle="dangan_label"
                                    :contentStyle="{'text-align': 'center'}"
                                    border
                                >
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>编号</span>
                                        </template>
                                        <span>{{CardRole.no}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>名称</span>
                                        </template>
                                        <span>{{CardRole.name}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>性别</span>
                                        </template>
                                        <el-tag size="small" effect="plain" v-if="CardRole.gender === 1">男</el-tag>
                                        <el-tag size="small" effect="plain" type="danger" v-else-if="CardRole.gender === 2">女</el-tag>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>系列</span>
                                        </template>
                                        <span>{{CardRole.group_name}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>属性</span>
                                        </template>
                                        <el-tag size="small" type="info" v-if="CardRole.hide === 0">隐藏角色</el-tag>
                                        <el-tag size="small" type="success" v-else-if="CardRole.hide === 1">公开角色</el-tag>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>机密类型</span>
                                        </template>
                                        <span>{{CardRole.secret_type_name}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :span=2>
                                        <template slot="label">
                                            <span>机密任务</span>
                                        </template>
                                        <span v-html="html_secret"></span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :span=2>
                                        <template slot="label">
                                            <span>角色密语</span>
                                        </template>
                                        <span>{{CardRole.slogan}}</span>
                                    </el-descriptions-item>
                                </el-descriptions>
                            </el-descriptions-item>
                            <el-descriptions-item :span=2>
                                <template slot="label">
                                    <div><img :src="$store.state.baseURL+'icon/jusejineng.png'" height="80px"></div>
                                    <div>角色技能</div>
                                </template>
                                <card-role-tabs-component ref="tabs_ref" :data="RoleSkill"></card-role-tabs-component>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="$_has('新增角色技能')"
                        type="success"
                        plain
                        round
                        @click="addSkillTeam"
                        >新增技能</el-button
                    >
                    <el-button
                        v-if="$_has('修改角色技能') && (RoleSkill.length > 0)"
                        type="primary"
                        plain
                        round
                        @click="editSkillTeam"
                        >修改技能</el-button
                    >
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改角色</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="CardRoleDel('CardRoleEditForm')"
                        >删除角色</el-button
                    >
                    <el-button
                        plain
                        @click="closeCardRoleDialog('CardRoleEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('CardRoleEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('CardRoleEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeCardRoleDialog('CardRoleEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('CardRoleEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="$emit('cancel_edit')"
                        >取消操作</el-button
                    >
                </div>
            </div>
            <card-role-skill-edit-component
                del_skill_auth="删除角色技能"
                :role_id="CardRole.id"
                :role_name="CardRole.name"
                :show_skill_type="edit_skill_type"
                :skillDialogFormVisible="skillDialogFormVisible"
                :card_role_skill_details_data="card_role_skill_details_data"
                :AllColor="AllColor"
                :AllType="AllType"
                @exitCardRoleSkillDialog="dialogExit"
                @search="GetCardRoleSkillRoleSkill"
            ></card-role-skill-edit-component>
        </el-dialog>
    </div>
</template>

<script>
import { CardRoleAddRequest,CardRoleDelRequest,CardRoleEditRequest } from '@/network/card/CardRole.js'
import { CardRoleSkillRoleSkillRequest } from '@/network/card/CardRoleSkill.js'

import CommonUploadComponent from '@/components/common/CommonUploadComponent'
import CommonCarouselComponent from '@/components/common/CommonCarouselComponent'
import CardRoleTabsComponent from '@/components/card/CardRole/CardRoleTabsComponent'
import CardRoleSkillEditComponent from '@/components/card/CardRole/CardRoleSkillEditComponent'
import CommonMp3Component from '@/components/common/CommonMp3Component'

export default {
    name: 'CardRoleEditComponent',
    data() {
        return {
            login_loading: false,
            CardRole: {
                no: '',
                name: '',
                slogan: '',
                gender: '',
                group: '',
                hide: '',
                secret_type: [],
                secret: '',
                picture:[],
                show_speech: [],
                win_speech: [],
                lost_speech: []
            },
            RoleSkill: [],
            //以下为技能详情框相关
            edit_skill_type: 0,
            skillDialogFormVisible: false,
            card_role_skill_details_data: {},
            AllColor: [
                {id:1,name:'红色'},
                {id:2,name:'黄色'},
                {id:3,name:'蓝色'},
                {id:4,name:'蓝红'}

            ],
            AllType: [
                {id:1,name:'主动技'},
                {id:2,name:'被动技'},
                {id:3,name:'转化技'},
                {id:4,name:'触发技'},
                {id:5,name:'触发类绝技'},
                {id:6,name:'响应技'},
                {id:7,name:'响应类绝技'},
                {id:8,name:'属性技'}
            ]
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '角色详情'
            } else if (this.show_type === 1) {
                return '添加角色'
            } else {
                return '修改角色'
            }
        },
        dangan_label() {
            if(this.CardRole.gender === 1) {
                return {'width':'90px','text-align':'center','background-color':'#C4E1FF'}
            } else if (this.CardRole.gender === 2) {
                return {'width':'90px','text-align':'center','background-color':'#FFD9EC'}
            } else {
                return {'width':'90px','text-align':'center'}
            }
            
        },
        html_secret() {
            let str = this.CardRole.secret.replace(/军情/g,'<span class="el-tag el-tag el-tag--plain">军情</span>')
            str = str.replace(/潜伏/g,'<span class="el-tag el-tag--danger el-tag--plain">潜伏</span>')
            str = str.replace(/酱油/g,'<span class="el-tag el-tag--success el-tag--plain">酱油</span>')
            str = str.replace(/红色/g,'<span style="font-weight: bold;color: #EA0000;">红色</span>')
            str = str.replace(/蓝色/g,'<span style="font-weight: bold;color: #0072E3;">蓝色</span>')
            str = str.replace(/黑色/g,'<span style="font-weight: bold;">黑色</span>')
            str = str.replace(/纯红/g,'<span style="font-weight: bold;color: #EA0000;">纯红</span>')
            str = str.replace(/纯蓝/g,'<span style="font-weight: bold;color: #0072E3;">纯蓝</span>')
            str = str.replace(/纯黑/g,'<span style="font-weight: bold;">纯黑</span>')
            str = str.replace(/红黑/g,'<span style="font-weight: bold;color: #EA0000;">红</span><span style="font-weight: bold;">黑</span>')
            str = str.replace(/蓝黑/g,'<span style="font-weight: bold;color: #0072E3;">蓝</span><span style="font-weight: bold;">黑</span>')
            str = str.replace(/红蓝/g,'<span style="font-weight: bold;color: #EA0000;">红</span><span style="font-weight: bold;color: #0072E3;">蓝</span>')
            str = str.replace(/红情报/g,'<span style="font-weight: bold;color: #EA0000;">红</span><span>情报</span>')
            str = str.replace(/蓝情报/g,'<span style="font-weight: bold;color: #0072E3;">蓝</span><span>情报</span>')
            str = str.replace(/黑情报/g,'<span style="font-weight: bold;">黑</span><span>情报</span>')
            return str
        }
    },
    methods: {
        closeCardRoleDialog(formName) {
            this.resetForm(formName)
            this.$emit('exitCardRoleDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.CardRoleInsert(formName)
            })
        },
        CardRoleInsert(formName) {
            this.login_loading = true
            this.CardRole.picture = this.$refs.upload.fileList
            this.CardRole.show_speech = this.$refs.show_upload.fileList
            this.CardRole.win_speech = this.$refs.win_upload.fileList
            this.CardRole.lost_speech = this.$refs.lost_upload.fileList
            CardRoleAddRequest(this.CardRole)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitCardRoleDialog')
                        this.$emit('search')
                        this.$emit('details_row',{id:this.id,slogan:this.card_role_details_data.slogan})
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.CardRole = {
                no: '',
                name: '',
                slogan: '',
                gender: '',
                group: '',
                hide: '',
                secret_type: '',
                secret: '',
                picture:[],
                show_speech: [],
                win_speech: [],
                lost_speech: []
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.CardRoleEdit(formName)
            })
        },
        CardRoleEdit(formName) {
            this.login_loading = true
            this.CardRole.id = this.id
            this.CardRole.picture = this.$refs.upload.fileList
            this.CardRole.show_speech = this.$refs.show_upload.fileList
            this.CardRole.win_speech = this.$refs.win_upload.fileList
            this.CardRole.lost_speech = this.$refs.lost_upload.fileList
            CardRoleEditRequest(this.CardRole)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitCardRoleDialog')
                        this.$emit('search')
                        this.$emit('details_row',{id:this.id,slogan:this.card_role_details_data.slogan})
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        CardRoleDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    CardRoleDelRequest(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeCardRoleDialog(formName))
                })
                .catch((err) => {})
        },
        GetCardRoleSkillRoleSkill() {
            this.login_loading = true
            CardRoleSkillRoleSkillRequest(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.RoleSkill = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        addSkillTeam() {
            this.edit_skill_type = 1
            this.skillDialogFormVisible = true
            this.card_role_skill_details_data = {with_role: this.id}
        },
        editSkillTeam() {
            this.edit_skill_type = 2
            this.skillDialogFormVisible = true
            const index = this.$refs.tabs_ref.activeName*1
            this.card_role_skill_details_data = this.RoleSkill[index]
        },
        dialogExit() {
            this.skillDialogFormVisible = false
            this.edit_skill_type = 0
        },
        show_clk() {},
        win_clk() {

        },
        lost_clk() {

        }
    },
    filters: {},
    props: {
        dialogFormVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        card_role_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        AllGender: {
            type: Array,
            default() {
                return []
            },
        },
        AllGroup: {
            type: Array,
            default() {
                return []
            },
        },
        AllHide: {
            type: Array,
            default() {
                return []
            },
        },
        AllSecretType: {
            type: Array,
            default() {
                return []
            },
        },
        edit_auth: String,
        del_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonUploadComponent,
        CommonCarouselComponent,
        CardRoleTabsComponent,
        CardRoleSkillEditComponent,
        CommonMp3Component
    },
    watch: {
        card_role_details_data(newVal) {
            this.CardRole = newVal
        },
        id(newVal) {
            if(newVal)
            {
                this.GetCardRoleSkillRoleSkill()
            }
        }
    },
}
</script>

<style lang='less' scoped>
.gender_c,.group_c,.hide_c,.secret_type_c {
    width: 100%
}

.font_family {
    font-size: 50px;
}
</style>