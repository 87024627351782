import request from '.././request'

//角色列表
export const CardRoleIndexRequest = p => {
    return request({
        method:'GET',
        url:'CardRole/index',
        params: p
    })
}

//添加角色
export const CardRoleAddRequest = d => {
    return request({
        method:'POST',
        url:'CardRole/add',
        data: d
    })
}

//删除角色
export const CardRoleDelRequest = id => {
    return request({
        method:'DELETE',
        url:'CardRole/del',
        data: {
            id: id
        }
    })
}

//修改角色
export const CardRoleEditRequest = data => {
    return request({
        method:'PUT',
        url:'CardRole/edit',
        data
    })
}

//获取角色详情
export const CardRoleDetailsRequest = id => {
    return request({
        method:'GET',
        url:'CardRole/details',
        params: {
            id
        }
    })
}

//角色彩蛋
export const CardRoleEggRequest = data => {
    return request({
        method:'PUT',
        url:'CardRole/egg',
        data
    })
}

//彩蛋皮肤
export const CardRoleEggSkillRequest = data => {
    return request({
        method:'PUT',
        url:'CardRole/egg_skill',
        data
    })
}