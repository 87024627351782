<template>
    <el-tabs
        v-model="activeName"
        tab-position="left"
        @tab-click="handleClick"
    >
        <el-tab-pane v-for="(item,index) in data" :key="item.id" :name="index+''">
            <template slot="label">
                <div style="text-align: center;">
                    <el-tag v-if="item.color === 1" type="danger">{{item.name}}</el-tag>
                    <el-tag v-else-if="item.color === 2" type="warning">{{item.name}}</el-tag>
                    <el-tag v-else-if="item.color === 3">{{item.name}}</el-tag>
                    <el-tag v-else-if="item.color === 4" type="danger" style="color: #7ABBFF;">{{item.name}}</el-tag>
                </div>
            </template>
            <el-descriptions
                class="margin-top"
                :column="3"
                :labelStyle="{'text-align': 'center','width':'120px'}"
                :contentStyle="{'text-align': 'center'}"
                border
            >
                <el-descriptions-item>
                    <template slot="label">
                        <span>技能名称</span>
                    </template>
                    <el-tag v-if="item.color === 1" type="danger" size="small">{{item.name}}</el-tag>
                    <el-tag v-else-if="item.color === 2" type="warning" size="small">{{item.name}}</el-tag>
                    <el-tag v-else-if="item.color === 3" size="small">{{item.name}}</el-tag>
                    <el-tag v-else-if="item.color === 4" type="danger" size="small" style="color: #7ABBFF;">{{item.name}}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>技能颜色</span>
                    </template>
                    <div v-html="skill_color"></div>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>技能类型</span>
                    </template>
                    <span>{{item.type_name}}</span>
                </el-descriptions-item>
                <el-descriptions-item :span=3 :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                    <template slot="label">
                        <span>技能效果</span>
                    </template>
                    <span style="font-weight: bold;color: #0000E3;">{{item.effect}}</span>
                </el-descriptions-item>
                <el-descriptions-item :span=3>
                    <template slot="label">
                        <span>【解析】</span>
                    </template>
                    <div class="el-tiptap-editor__content" v-html="item.note"></div>
                </el-descriptions-item>
            </el-descriptions>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
export default {
    name:'',
    data(){
        return {
            activeName: 0
        }
    },
    computed:{
        skill_color() {
            const index = this.activeName*1
            if(this.data[index].color===1) {
                return `<span style="color:#CE0000 ;">`+this.data[index].color_name+`</span>`
            } else if(this.data[index].color===2) {
                return `<span style="color:#EAC100 ;">`+this.data[index].color_name+`</span>`
            } else if(this.data[index].color===3) {
                return `<span style="color:#0080FF ;">`+this.data[index].color_name+`</span>`
            } else {
                return `<span style="color:#0080FF ;">蓝</span><span style="color:#CE0000 ;">红</span>`
            }
        }
    },
    methods:{
        handleClick(tab, event) {
            const index = this.activeName*1
            //取语音数组
            const speech = this.data[index].speech
            //将语音数组传给VUEX进行处理
            this.$store.commit('edit_speech_url', speech)
        }
    },
    filters:{},
    props:{
        data: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{
        data(newVal) {
            this.activeName = '0'
        }
    },
}
</script>

<style lang='less' scoped></style>