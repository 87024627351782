<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="skillDialogFormVisible"
            :append-to-body="true"
            width="900px"
            :center="true"
            top="5px"
            :close-on-click-modal="false"
            :before-close="() => closeCardRoleSkillDialog('CardRoleSkillEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="CardRoleSkill"
                    ref="CardRoleSkillEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div>
                        <el-descriptions
                            class="margin-top"
                            :column="2"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>所属角色</span>
                                </template>
                                <span>{{role_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>技能名称</span>
                                </template>
                                <el-input v-model="CardRoleSkill.name" clearable></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>技能类型</span>
                                </template>
                                <el-select
                                    v-model="CardRoleSkill.type"
                                    filterable
                                    clearable
                                    multiple
                                    :multiple-limit="2"
                                    placeholder="选择类型"
                                    class="type_c"
                                >
                                    <el-option
                                        v-for="item in AllType"
                                        :key="item.name"
                                        :label="item.name"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>技能颜色</span>
                                </template>
                                <el-radio-group v-model="CardRoleSkill.color" size="small" :fill="redio_color">
                                    <el-radio-button
                                        v-for="item in AllColor"
                                        :key="item.name"
                                        :label="item.id"
                                    >{{item.name}}</el-radio-button>
                                </el-radio-group>
                            </el-descriptions-item>
                            <el-descriptions-item :span=2>
                                <template slot="label">
                                    <span>技能效果</span>
                                </template>
                                <el-input type="textarea" :rows="3" v-model="CardRoleSkill.effect"></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item :span=2>
                                <template slot="label">
                                    <span>【解析】</span>
                                </template>
                                <common-editor-component
                                    ref="editor"
                                    :fcontent="CardRoleSkill.note"
                                ></common-editor-component>
                            </el-descriptions-item>
                            <el-descriptions-item :span=2>
                                <template slot="label">
                                    <span>技能语音</span>
                                </template>
                                <common-mp3-component
                                    ref="upload"
                                    :limit=10
                                    :ffileList="CardRoleSkill.speech"
                                    note='请以语音内容为文件名，可以使用中文字符，不要使用英文字符'
                                ></common-mp3-component>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_skill_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('CardRoleSkillEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('CardRoleSkillEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeCardRoleSkillDialog('CardRoleSkillEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('CardRoleSkillEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        v-if="$_has(del_skill_auth)"
                        type="warning"
                        plain
                        @click="CardRoleSkillDel('CardRoleSkillEditForm')"
                        >删除技能</el-button
                    >
                    <el-button
                        plain
                        @click="closeCardRoleSkillDialog('CardRoleSkillEditForm')"
                        >取消操作</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { CardRoleSkillAddRequest,CardRoleSkillDelRequest,CardRoleSkillEditRequest } from '@/network/card/CardRoleSkill.js'
import CommonEditorComponent from '@/components/common/CommonEditorComponent'
import CommonMp3Component from '@/components/common/CommonMp3Component'

export default {
    name: 'CardRoleSkillEditComponent',
    data() {
        return {
            login_loading: false,
            CardRoleSkill: {
                name: '',
                color: '',
                type: '',
                effect: '',
                speech: [],
                note: ''
            }
        }
    },
    computed: {
        dialogFormTitle() {
            if (this.show_skill_type === 1) {
                return '添加技能'
            } else {
                return '修改技能'
            }
        },
        redio_color() {
            let str = ''
            if(this.CardRoleSkill.color === 1) {
                str='#CE0000'
            } else if (this.CardRoleSkill.color === 2) {
                str='#EAC100'
            } else if (this.CardRoleSkill.color === 3) {
                str='#0080FF'
            } else if (this.CardRoleSkill.color === 4) {
                str='#CA8EFF'
            }

            return str
        },
        skill_name() {
            if(this.CardRoleSkill.color===1) {
                return `<div class="cell"><span class="el-tag el-tag--danger el-tag--dark el-tag--small">`+this.CardRoleSkill.name+`</span></div>`
            } else if(this.CardRoleSkill.color===2) {
                return `<div class="cell"><span class="el-tag el-tag--warning el-tag--dark el-tag--small">`+this.CardRoleSkill.name+`</span></div>`
            } else {
                return `<div class="cell"><span class="el-tag el-tag--dark el-tag--small">`+this.CardRoleSkill.name+`</span></div>`
            }
        },
        skill_color() {
            if(this.CardRoleSkill.color===1) {
                return `<span style="color:#CE0000 ;">`+this.CardRoleSkill.color_name+`</span>`
            } else if(this.CardRoleSkill.color===2) {
                return `<span style="color:#EAC100 ;">`+this.CardRoleSkill.color_name+`</span>`
            } else if(this.CardRoleSkill.color===3) {
                return `<span style="color:#0080FF ;">`+this.CardRoleSkill.color_name+`</span>`
            } else if(this.CardRoleSkill.color===4) {
                return `<span style="color:#CA8EFF ;">`+this.CardRoleSkill.color_name+`</span>`
            }
        }
    },
    methods: {
        closeCardRoleSkillDialog(formName) {
            this.$emit('exitCardRoleSkillDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.CardRoleSkillInsert(formName)
            })
        },
        CardRoleSkillInsert(formName) {
            this.login_loading = true
            this.CardRoleSkill.with_role = this.role_id
            this.CardRoleSkill.note = this.$refs.editor.content
            this.CardRoleSkill.speech = this.$refs.upload.fileList 
            CardRoleSkillAddRequest(this.CardRoleSkill)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitCardRoleSkillDialog')
                        this.$emit('search')
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.CardRoleSkill = {
                name: '',
                color: '',
                type: '',
                effect: '',
                speech: [],
                note: ''
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.CardRoleSkillEdit(formName)
            })
        },
        CardRoleSkillEdit(formName) {
            this.login_loading = true
            this.CardRoleSkill.with_role = this.role_id
            this.CardRoleSkill.note = this.$refs.editor.content
            this.CardRoleSkill.speech = this.$refs.upload.fileList 
            CardRoleSkillEditRequest(this.CardRoleSkill)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitCardRoleSkillDialog')
                        this.$emit('search')
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        CardRoleSkillDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    CardRoleSkillDelRequest(this.CardRoleSkill.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeCardRoleSkillDialog(formName))
                })
                .catch((err) => {})
        },
    },
    filters: {},
    props: {
        skillDialogFormVisible: Boolean,
        card_role_skill_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_skill_type: {
            type:Number,
            default() {
                return 0
            },
        },
        AllColor: {
            type: Array,
            default() {
                return []
            }
        },
        AllType: {
            type: Array,
            default() {
                return []
            }
        },
        role_id: {
            type: Number,
            default() {
                return 0
            }
        },
        role_name: {
            type: String,
            default() {
                return ''
            }
        },
        edit_skill_auth: String,
        del_skill_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonEditorComponent,
        CommonMp3Component
    },
    watch: {
        card_role_skill_details_data(newVal) {
            this.CardRoleSkill = newVal
        },
    },
}
</script>

<style lang='less' scoped>
.color_c,.type_c {
    width: 100%;
}
</style>