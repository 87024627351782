<template>
    <el-carousel
        :type="type"
        :height="height"
        :autoplay="autoplay"
        :interval="interval"
        :direction="direction"
    >
        <el-carousel-item v-for="item in data" :key="item.name">
            <img :src="item.url" height="200px" alt="" style="border-radius: 10px;">
        </el-carousel-item>
    </el-carousel>
</template>

<script>
export default {
    name:'',
    data(){
        return {}
    },
    computed:{},
    methods:{},
    filters:{},
    props:{
        type: {
            type: String,
            default() {
                return ''
            }
        },
        height: {
            type: String,
            default() {
                return '200px'
            }
        },
        autoplay: {
            type: Boolean,
            default() {
                return true
            }
        },
        interval: {
            type: Number,
            default() {
                return 5000
            }
        },
        direction: {
            type: String,
            default() {
                return 'horizontal'
            }
        },
        data: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{},
}
</script>

<style lang='less'></style>