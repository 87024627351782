import request from '.././request'

//获取角色技能
export const CardRoleSkillRoleSkillRequest = with_role => {
    return request({
        method:'GET',
        url:'CardRoleSkill/RoleSkill',
        params: {
            with_role
        }
    })
}

//添加角色技能
export const CardRoleSkillAddRequest = d => {
    return request({
        method:'POST',
        url:'CardRoleSkill/add',
        data: d
    })
}

//删除角色技能
export const CardRoleSkillDelRequest = id => {
    return request({
        method:'DELETE',
        url:'CardRoleSkill/del',
        data: {
            id: id
        }
    })
}

//修改角色技能
export const CardRoleSkillEditRequest = data => {
    return request({
        method:'PUT',
        url:'CardRoleSkill/edit',
        data
    })
}

//获取角色技能详情
export const CardRoleSkillDetailsRequest = id => {
    return request({
        method:'GET',
        url:'CardRoleSkill/details',
        params: {
            id
        }
    })
}