<template>
    <el-upload
        :class="{hide: this.hideUpload}"
        :action="this.$store.state.baseURL + 'UploadFile'"
        :on-exceed="handleExceed"
        :limit="limit"
        :on-preview="handlePreview"
        :before-upload="beforeUpload"
        :before-remove="beforeRemove"
        :on-remove="onRemove"
        :on-success="onSuccess"
        :file-list="fileList"
        :headers="headers"
        :disabled="disabled"
    >
    <el-button v-if="!this.hideUpload" size="small" type="primary">{{ note }}</el-button>
    </el-upload>
</template>

<script>
export default {
    name:'CommonUploadComponent',
    data(){
        return {
            fileList: [],
            dialogVisible: false,
            dialogImageUrl: '',
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('token')
            },
            hideUpload: false
        }
    },
    computed:{},
    methods:{
        onSuccess(response, file, fileList) {
            if(response.status !==0) {
                this.$message.error(response.msg)
                return
            }

            if(fileList.length >= this.limit) {
                this.hideUpload = true
            }

            this.fileList.push({
                name: file.name,
                url: this.$store.state.baseURL + response.result.url
            })
        },
        handlePreview(file) {
            const name_arr = file.name.split('.');
            if((name_arr[name_arr.length-1] === 'jpg') || (name_arr[name_arr.length-1] === 'png') || (name_arr[name_arr.length-1] === 'gif')) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            } else {
                window.open(file.url, '_blank')
            }
        },
        handleExceed(files, fileList) {
            this.$message.warning(`最大允许上传`+this.limit+`个文件`)
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${ file.name }？`);
        },
        onRemove(file,fileList) {
            this.fileList = fileList
            if(this.fileList.length < this.limit) {
                this.hideUpload = false
            }
        },
        beforeUpload(file) {
            const arr = [
                'audio/mpeg'   //语音文件
                // 'application/msword',   //doc格式word
                // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',   //docx格式word
                // 'application/vnd.ms-excel',     //xls格式表格
                // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',    //xlsx格式表格
                // 'application/vnd.ms-powerpoint',    //ppt格式幻灯片
                // 'application/vnd.openxmlformats-officedocument.presentationml.presentation',    //pptx格式幻灯片
                // 'text/plain',   //txt文本
                // 'application/pdf'   //pdf
            ]
            const InArr = (arr.indexOf(file.type) > -1)
            const OverStepSize = (file.size / 1024 / 1024 < 1)

            if (!InArr) {
                this.$message.error('超出可上传文件类型!')
                return
            }
            if (!OverStepSize) {
                this.$message.error('超出可上传文件大小')
                return
            }

            return InArr && OverStepSize;
        }
    },
    filters:{},
    props:{
        ffileList: {
            type: Array,
            default() {
                return []
            }  
        },
        disabled: {
            type: Boolean,
            default() {
                return false
            }
        },
        limit: {
            type: Number,
            default() {
                return 3
            }
        },
        note: {
            type: String,
            default() {
                return '点击上传'
            }
        }
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{
        ffileList(newVal) {
            this.fileList = newVal
        }
    }
}
</script>

<style lang='less'>
.hide .el-upload--picture-card {
    display: none;
}
</style>